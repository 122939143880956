import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteEnum} from './core/enums/route-enum';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./evaluations/evaluations-routing.module').then(m => m.EvaluationsRoutingModule)
  },
  {
    path: '**',
    redirectTo: RouteEnum.Home
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IStudyEvaluationsRoutingModule {}
