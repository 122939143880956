import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteEnum} from './core/enums/route-enum';

const routes: Routes = [
  {
    path: RouteEnum.Events,
    loadChildren: () => import('./events/events-routing.module').then(m => m.EventsRoutingModule)
  },
  {
    path: RouteEnum.Statistics,
    loadChildren: () => import('./statistics/statistics-routing.module').then(m => m.StatisticsRoutingModule)
  },
  {
    path: RouteEnum.Templates,
    loadChildren: () => import('./templates/templates-routing.module').then(m => m.TemplatesRoutingModule)
  },
  {
    path: '**',
    redirectTo: RouteEnum.Home
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IStudyRoutingModule {}
