export enum RouteEnum {
  Home = '/',
  Add = 'add',
  Aak = 'aak', // base route
  Dashboard = 'dashboard',
  Events = 'events',
  Contacts = 'contacts',
  Organizations = 'organizations',
  Statistics = 'statistics',
  Evaluations = 'evaluations',
  Templates = 'templates',
  Mail = 'mail',
  Certificates = 'certificates',
  Courses = 'courses',
  Participants = 'participants',
  Reports = 'reports',
  Calendar = 'calendar'
}
